import styled from "styled-components";
import { Menu, Popover } from "antd";
import { accountSettings } from "../../constants/settings";
import { ActionsMenu } from "../common/FormActions";
import { AccountCircle } from "@mui/icons-material";
import { alpha, IconButton } from "@mui/material";
import { primaryColor } from "../styled/Theme";

const AppHeaderDropdown = () => {
  return (
    <>
      <Popover
        content={
          <>
            <Menu mode="inline" className="font13 rightmenu">
              {ActionsMenu(accountSettings)}
            </Menu>
          </>
        }
        trigger="click"
        overlayStyle={{ minWidth: 200 }}
        overlayInnerStyle={{ padding: 5 }}
        placement="bottomRight"
      >
        <AvatarWrapper className="animate">
          <IconButton sx={{ bgcolor: alpha(primaryColor, 0.1) }}>
            <AccountCircle className="mainColor" />
          </IconButton>
        </AvatarWrapper>
      </Popover>
    </>
  );
};

export default AppHeaderDropdown;

const AvatarWrapper = styled.div`
  &:hover {
    scale: 1.1;
  }
`;
