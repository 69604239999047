import { Popover } from "antd";
import { ItemWrapper } from "./Elements";
import { IconButton } from "@mui/material";
import {
  CircleOutlined,
  Create,
  MoreVert,
  Visibility,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { EDIT, VIEW } from "../../utils/constants";

type TActionProps = {
  label: string;
  to?: string;
  icon?: any;
  onClick?: () => void;
};
type TFormActionsProps = {
  actions: TActionProps[];
};

function getIcon(label: string) {
  switch (label) {
    case VIEW:
      return <Visibility fontSize="small" />;
    case EDIT:
      return <Create fontSize="small" />;
    default:
      return <CircleOutlined sx={{ fontSize: 8, width: 20 }} />;
  }
}

export const ActionsMenu = (actions: any[]) => {
  return (
    <>
      {actions?.map(
        ({ label, to, onClick, icon: Icon }: TActionProps, key: number) => (
          <Link to={to || ""} onClick={onClick} key={key}>
            <ItemWrapper>
              {(Icon && <Icon fontSize="small" />) || getIcon(label)}
              {label}
            </ItemWrapper>
          </Link>
        ),
      )}
    </>
  );
};

export const FormActions = ({ actions }: TFormActionsProps) => {
  return (
    <Popover
      content={<>{ActionsMenu(actions)}</>}
      trigger="click"
      overlayStyle={{ minWidth: 150 }}
      overlayInnerStyle={{ padding: 5 }}
      placement="left"
    >
      <IconButton size="small">
        <MoreVert />
      </IconButton>
    </Popover>
  );
};
