import { ReactNode } from "react";
import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";
import { useProfile } from "../../hooks/profile/useProfile";

export const DashboardLayout = ({ children }: { children: ReactNode }) => {
  const { profile } = useProfile();

  return (
    <>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader profile={profile} />
        {children}
      </div>
    </>
  );
};
