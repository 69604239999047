// GENERAL
export const API_CONFIGURABLE = "configurables/";

// USERS MANAGEMENT
export const API_PROFILE = "accounts/users/1";
export const API_USERS = "accounts/users/";
export const API_REGISTER_USER = "accounts/register-user/";
export const API_LOGIN = "token/";
export const API_RESET_PASSWORD = "password_reset/confirm/";
export const API_VERIFY_PASSWORD_RESET_TOKEN = "password_reset/validate_token/";
export const API_REQUEST_PASSWORD_RESET = "password_reset/";
export const API_ACCOUNTS_ROLES = "accounts/roles/";
export const API_ACCOUNTS_PROFILES = "accounts/profiles/";

//LOAN APPLICATIONS
export const API_LOAN_APPLICATIONS = "loan-applications/";

// INVENTORY MANAGEMENT
export const API_PURCHASE_ORDERS = "purchase-orders/";
export const API_PURCHASE_ORDER_ITEMS = "order-inventories/";
export const API_INVENTORY = "inventories/";
export const API_PRODUCTS = "products/";

// PAYROLL MANAGEMENT
export const API_PAYROLL = "payrolls/";

//LOCATION DETAILS
export const API_DISTRICTS = "districts/";
export const API_COUNTIES = "counties/";
export const API_SUB_COUNTIES = "sub-counties/";
export const API_PARISHES = "parishes/";
export const API_VILLAGES = "villages/";
export const API_REGIONS = "regions/";

//CONFIGURABLES
export const API_CONFIGURABLES = "configurables/other/";
export const API_INTEREST_CONFIGURABLES =
  "configurables/interest-configurations/";
export const API_PERIOD_CONFIGURABLES =
  "configurables/loan-validity-period-configurations/";
export const API_FEES_CONFIGURABLES =
  "configurables/fees-schedule-configurations/";
export const API_CHARTS_OF_ACCOUNTS = "accountings/charts/";
export const API_SUPPLIER_CONFIGURABLES = "configurables/suppliers/";
export const API_ALLOWANCE_TYPE_CONFIGURABLES =
  "configurables/allowance-types/";

//VALIDATE NIN
export const VALIDATE_NIN_API = `accounts/register-user/validate-nin/`;

// AUDIT ACTIVITY
export const API_AUDIT_ACTIVITY = "audits/activity/";

// OFFICES
export const API_DEPARTMENTS = "departments/";

// ORGANISATIONS
export const API_ORGANISATIONS = "organisations/";
// Accounts
export const API_LEDGERS = "accountings/ledgers/";

// Expenses
export const API_EXPENSES = "expenses/";

// Other Income
export const API_OTHER_INCOME = "other-incomes/";
// Payments
export const API_INVOICES = "billings/invoices/";
export const API_PRNS = "payments/prns/";
// REPORTS
// REPORTS
export const API_REPORTS_DASHBOARD = "reports/dashboard/";

// PERMISSIONS
export const API_ACCOUNTS_PERMISSIONS = "accounts/permissions/";

// BILLINGS
export const API_BILLINGS_FEES = "billings/fees/";
export const API_BILLING_INSTALMENTS = "billings/instalments/";
