import { useEffect } from "react";
import useGet from "../common/useGet";
import { API_USERS } from "../../utils/apis";
import { useAuth } from "../../context/AuthContext";
import { useLocalStorage } from "../common/useLocalStorage";
import { PROFILE } from "../../utils/constants";

export const useProfile = () => {
  const { user, updateUser } = useAuth();
  const { loadData, data: profile, loading } = useGet();
  const { setData } = useLocalStorage();

  useEffect(() => {
    if (user?.id) {
      loadData({ api: `${API_USERS}${user?.id}` });
    }
  }, [user?.id]);

  useEffect(() => {
    if (profile) {
      setData(PROFILE, profile);
      updateUser(profile);
    }
  }, [profile]);

  return { profile, loading };
};
