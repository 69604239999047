import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";
import { useAuth } from "../../context/AuthContext";
import { BASE_API_URL } from "../../config";

type TParams = {
  api: string;
  methodName?: AxiosRequestConfig["method"];
  loader?: (isLoading: boolean) => void;
  successHandler?: (data: any) => void;
  errorHandler?: (error: string | any) => void;
  params?: object;
  isExternal?: boolean;
  type?: "application/json" | "multipart/form-data";
};

function useAxios() {
  const { authToken, logout } = useAuth();

  const handleResponse = (
    resp: any,
    successHandler?: (data: any) => void,
    loader?: (isLoading: boolean) => void,
  ) => {
    const { data } = resp;
    if (successHandler) successHandler(data);
    if (loader) loader(false);
  };

  const handleError = (
    resp: AxiosError,
    errorHandler?: (error: string | any) => void,
    loader?: (isLoading: boolean) => void,
  ) => {
    let message: string | object = "An unknown error occurred";

    if (resp.response?.status === 401) {
      logout();
    } else if (resp.response?.status === 500) {
      message = "Internal server error occurred";
    } else if (resp.response?.status === 402) {
      message = "Server timeout error occurred";
    } else {
      message = resp?.response?.data || "An unknown error occurred";
    }

    if (errorHandler) errorHandler(message);
    if (loader) loader(false);
  };

  const createRequestConfig = (
    options: TParams,
    method: AxiosRequestConfig["method"],
  ): AxiosRequestConfig => {
    const { api, isExternal, params, type } = options;
    return {
      method,
      headers: {
        "Content-Type": type || "application/json",
        ...(!isExternal && authToken
          ? { Authorization: `Bearer ${authToken}` }
          : {}),
      },
      url: isExternal ? api : `${BASE_API_URL}${api}`,
      ...(method === "get" ? { params } : { data: params }),
    };
  };

  const get = async (options: TParams): Promise<any> => {
    const { loader, successHandler, errorHandler } = options;
    try {
      if (loader) loader(true);
      const resp = await axios(createRequestConfig(options, "get"));
      handleResponse(resp, successHandler, loader);
    } catch (resp) {
      handleError(resp as AxiosError, errorHandler, loader);
    }
  };

  const set = async (options: TParams): Promise<any> => {
    const { loader, successHandler, errorHandler, methodName } = options;
    try {
      if (loader) loader(true);
      const resp = await axios(
        createRequestConfig(options, methodName || "post"),
      );
      handleResponse(resp, successHandler, loader);
    } catch (resp) {
      handleError(resp as AxiosError, errorHandler, loader);
    }
  };

  return { get, set };
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token && config.headers) {
      (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.config.url !== "/api/auth/login/" &&
      error.response.status === 401
    ) {
      localStorage.removeItem("token");
      const loginUrl = "/login";
      if (window.location.href !== loginUrl) {
        window.location.href = loginUrl;
        return;
      }
    }
    return Promise.reject(error);
  },
);

export default useAxios;
