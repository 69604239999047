import { useEffect, useRef } from "react";
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from "@coreui/react";
import AppHeaderDropdown from "./AppHeaderDropdown";
import { useNav } from "../../context/NavContext";
import { getForm, stringifyName } from "../../utils/helpers";
import { BASE_MEDIA_API_URL } from "../../config";
import { MenuOpenRounded } from "@mui/icons-material";
import {
  Add,
  NotificationsOutline,
  SettingsOutline,
} from "@raresail/react-ionicons";
import { IconButton } from "@mui/material";
import { Button } from "antd";
import { LOAN_APPLICATION } from "../../utils/constants";
import { Link } from "react-router-dom";
import { URL_SETTINGS } from "../../utils/urls";
import { useAuth } from "../../context/AuthContext";
import ReportsDropdown from "./ReportsDropdown";

const AppHeader = ({ profile }) => {
  const { user } = useAuth();

  const headerRef = useRef<HTMLDivElement>();
  const { sidebarShow, setSidebarShow } = useNav();

  useEffect(() => {
    if (profile) {
      window.document.title = `Loans: ${stringifyName(profile)}`;
    }
  }, [profile]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      headerRef.current &&
        headerRef.current?.classList.toggle(
          "shadow-sm",
          document.documentElement.scrollTop > 0,
        );
    });
  }, []);

  return (
    <CHeader
      style={{ zIndex: 100 }}
      position="sticky"
      className="mb-4 p-0"
      ref={headerRef as any}
    >
      <CContainer className="border-bottom px-4" fluid>
        <CHeaderToggler
          onClick={() => setSidebarShow(!sidebarShow)}
          style={{ marginInlineStart: "-14px" }}
        >
          <MenuOpenRounded />
        </CHeaderToggler>

        <div
          style={{ lineHeight: 1.2, marginLeft: 10 }}
          className="flexNullCenter gap10"
        >
          <img
            src={`${BASE_MEDIA_API_URL}${user?.organisation_logo}`}
            height={28}
          />
          <div className="mainColor bold font20">{user?.organisation_name}</div>
          <CHeaderNav>
            <ReportsDropdown />
          </CHeaderNav>
        </div>

        <CHeaderNav className="ms-auto gap10" />
        <div className="flexNullCenter gap10">
          <Link to={getForm(LOAN_APPLICATION)}>
            <Button
              className="font12"
              type="primary"
              icon={<Add fontSize={15} />}
              style={{ borderRadius: 20 }}
            >
              New Loan
            </Button>
          </Link>
          <IconButton size="small" sx={{ bgcolor: "#eee" }}>
            <NotificationsOutline />
          </IconButton>
          <Link to={URL_SETTINGS}>
            <IconButton size="small" sx={{ bgcolor: "#eee" }}>
              <SettingsOutline />
            </IconButton>
          </Link>

          <CHeaderNav>
            <AppHeaderDropdown />
          </CHeaderNav>
        </div>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
