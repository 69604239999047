import { Button, Image } from "antd";
import { downloadThroughAnchorLink, isImage } from "../../utils/helpers";
import { DownloadOutlined, ImageOutlined } from "@mui/icons-material";
import { BASE_MEDIA_API_URL } from "../../config";
import { useState } from "react";
import { ReactComponent as FilePdfIcon } from "../../assets/svg/pdf-icon.svg";

export const FileActionView = ({ uri, item, fileName }) => {
  const [visible, setVisible] = useState(false);
  const scaleStep = 0;

  return (
    <>
      <span>
        <Button
          type="primary"
          className="btn-secondary"
          ghost
          icon={
            isImage(fileName) ? (
              <ImageOutlined />
            ) : (
              <FilePdfIcon
                style={{ fontSize: "1rem" }}
                fill="var(--primary-color)"
              />
            )
          }
          onClick={() =>
            isImage(fileName)
              ? setVisible(true)
              : downloadThroughAnchorLink(uri, item.name || fileName)
          }
        >
          {isImage(fileName) ? "View" : "Download"}
        </Button>
        {visible && (
          <Image
            style={{ display: "none" }}
            src={uri}
            preview={{
              visible,
              scaleStep,
              src: uri,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        )}
      </span>
      {isImage(fileName) && (
        <span style={{ margin: 5 }}>
          <Button
            type="primary"
            className="btn-primary"
            ghost
            icon={<DownloadOutlined />}
            onClick={() =>
              downloadThroughAnchorLink(uri, item.name || fileName)
            }
          />
        </span>
      )}
    </>
  );
};

export const renderSingleFile = (item, isLocal = false) => {
  const uri = isLocal
    ? window.URL.createObjectURL(item?.file)
    : `${BASE_MEDIA_API_URL}${item?.file}`;

  const fileName = isLocal ? item?.file?.name : item.file;

  return <FileActionView uri={uri} item={item} fileName={fileName} />;
};

/* DOCUMENT EDITOR */
