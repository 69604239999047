import React from "react";
import { CCloseButton, CSidebar } from "@coreui/react";
import { useNav } from "../../context/NavContext";
import { stringifyName } from "../../utils/helpers";
import { useAuth } from "../../context/AuthContext";
import { LeftMenu } from "./LeftMenu";
import styled from "styled-components";
import { alpha } from "@mui/material";
import { primaryColor } from "../styled/Theme";

const AppSidebar = () => {
  const { sidebarShow, setSidebarShow } = useNav();
  const { user } = useAuth();
  const role = user?.role_details?.[0]?.name;

  return (
    <CSidebar
      // className="sidebar-dark bg-theme"
      position="fixed"
      unfoldable={false}
      visible={sidebarShow}
      style={{ overflow: "scroll", borderRight: "1px solid #eee" }}
      onVisibleChange={(visible) => {
        setSidebarShow(visible);
      }}
    >
      <div
        style={{ borderBottom: "1px solid #eee", position: "relative" }}
        className="p10"
      >
        <div className="flexNullCenter gap10 ">
          {/* NAME ICON */}
          <div
            className="font25 extraBold primaryColor flexCenter radius10"
            style={{
              width: 45,
              height: 45,
              backgroundColor: alpha(primaryColor, 0.1),
            }}
          >
            {user?.first_name?.substring(0, 1)}
          </div>

          <div className="flexGrow">
            <NameWrapper className="font14 bold">
              {stringifyName(user)}
            </NameWrapper>
            <div className="font12">{role}</div>
          </div>

          <CCloseButton
            className="d-lg-none"
            onClick={() => setSidebarShow(false)}
          />
        </div>
      </div>

      <LeftMenu />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);

const NameWrapper = styled.div`
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 30px);
`;
