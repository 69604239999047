// References to environment variables should be defined here

export const BASE_API_URL = process.env.REACT_APP_BASE_API as string;
export const BASE_MEDIA_API_URL = process.env
  .REACT_APP_BASE_MEDIA_API as string;
export const MAPBOX_ACCESS_TOKEN = process.env
  .REACT_APP_MAPBOX_ACCESS_TOKEN as string;

export const FLEX_MONSTER_KEY = process.env
  .REACT_APP_FLEX_MONSTER_KEY as string;
